body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.card-form{
  margin: 40px;
  padding: 40px;
  border: 1px solid rgb(252, 165, 165)
}

#submit{
  background-color: rgb(252, 165, 165);
}

