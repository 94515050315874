 /* Variables */
 :root {
     --body-color: rgb(247, 250, 252);
     --button-color: rgb(30, 166, 114);
     --accent-color: #0a721b;
     --secondary-color: #ed5f74;
     --link-color: #ffffff;
     --font-color: rgb(105, 115, 134);
     --gray-border: rgba(0, 0, 0, 0.15);
     --gray-light: rgba(0, 0, 0, 0.4);
     --body-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
     --radius: 6px;
     --form-width: 400px;
   }
  
   .form-spinner {
     height: 30px;
     width: 30px;
     border: solid 3px grey;
     border-radius: 50%;
     border-bottom-color: transparent;
     border-right-color: transparent;
     will-change: transform;
     animation: spin 700ms infinite linear;
     margin: 0px auto;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50% , -50%);
     -webkit-transform: translate(-50%, -50%);
   }
  
   @keyframes spin {
     from {
       transform: rotate(0deg);
     }
     to {
       transform: rotate(360deg);
     }
   }
  
   /* Layout */
   .form-root {
     position: relative;
   }
  
   .form-field-error {
     color: red;
     text-align: left;
     font-size: 13px;
     line-height: 17px;
     margin-top: 12px;
   }
  
   .form-field-success {
     color: green;
     text-align: left;
     font-size: 13px;
     line-height: 17px;
     margin-top: 12px;
   }
  
   /* Inputs */
   .form-input,
   input[type="text"] {
     border: 1px solid var(--gray-border);
     border-radius: var(--radius);
    //  padding: 5px 12px;
    //  height: 44px;
     width: 100%;
     transition: box-shadow 0.2s ease;
     background: white;
     -moz-appearance: none;
     -webkit-appearance: none;
     appearance: none;
   }
   .form-input:focus,
   input[type="text"]:focus,
   button:focus,
   .focused {
     box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
       0 0 0 4px rgba(50, 151, 211, 0.3);
     outline: none;
     z-index: 9;
   }
   .form-input::placeholder,
   input[type="text"]::placeholder {
     color: var(--gray-light);
   }
  
  
   .form-combo-inputs-row {
     box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
       0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
     border-radius: 7px;
   }
  
   /* Form */
   .form-row {
     margin: 16px 0;
   }
  
   label {
     font-size: 13px;
     font-weight: 500;
     margin-bottom: 8px;
     text-align: left;
     display: block;
   }
  
   /* Buttons and links */
   button {
     background: var(--accent-color);
     border-radius: var(--radius);
     color: white;
     border: 0;
     padding: 12px 16px;
     margin-top: 16px;
     font-weight: 600;
     cursor: pointer;
     transition: all 0.2s ease;
     display: block;
     width: 100%;
   }
   button:hover {
     filter: contrast(215%);
   }
  
   /* Stripe Element placeholder */
   .form-card-element {
     padding-top: 12px;
     padding-bottom: 12px;
     padding-left: 12px;
     box-sizing: border-box;
   }
  
   @-webkit-keyframes loading {
     0% {
       -webkit-transform: rotate(0deg);
       transform: rotate(0deg);
     }
     100% {
       -webkit-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
   @keyframes loading {
     0% {
       -webkit-transform: rotate(0deg);
       transform: rotate(0deg);
     }
     100% {
       -webkit-transform: rotate(360deg);
       transform: rotate(360deg);
     }
   }
  
   .form-hidden {
     display: none !important;
   }
